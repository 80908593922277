import { render, staticRenderFns } from "./UserSettingsDialog.vue?vue&type=template&id=4db2b549"
import script from "./UserSettingsDialog.vue?vue&type=script&lang=js"
export * from "./UserSettingsDialog.vue?vue&type=script&lang=js"
import style0 from "../css-mixins/buttons.css?vue&type=style&index=0&prod&module=buttons&lang=css&external"
import style1 from "./UserSettingsDialog.vue?vue&type=style&index=1&id=4db2b549&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["buttons"] = (style0.locals || style0)
this["$style"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "./UserSettingsDialog.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports