import Currency from 'fb-utils/currency';


//<script type="text/javascript">
    var Currencies = {};
    var exponent;
        
    exponent = [];
        exponent.push(3);
    Currencies['EUR'] = new Currency('€~', '-€~', 0, '.', exponent, '', false, "1");
    Currencies['EUR'].MinValue = 10;
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['GBP'] = new Currency('£~', '-£~', 0, '.', exponent, ',', false, "1");
    Currencies['GBP'].MinValue = 10;
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['AUD'] = new Currency('~ AU$', '-~ AU$', 0, '.', exponent, ',', false, "1");
    Currencies['AUD'].MinValue = 15;
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['CAD'] = new Currency('~ CA$', '-~ CA$', 0, '.', exponent, ',', false, "1");
    Currencies['CAD'].MinValue = 15;
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['HKD'] = new Currency('~ HK$', '-~ HK$', 0, '.', exponent, ',', false, "1");
    Currencies['HKD'].MinValue = 80;
    
        
        
    exponent = [];
        exponent.push(3);
    Currencies['USD'] = new Currency('$~', '($~)', 0, '.', exponent, ',', false, "1");
    Currencies['USD'].MinValue = 10;
    
        
//</script>


export default Currencies;