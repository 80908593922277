import Deferred from './deferred';
import ws from 'fb-utils/webservice';
import _ from 'underscore';
import UserInfo2 from './models/UserInfo2';
import app from 'utils/appInstance';

let __instance = null;

export default class AuthManager {
    constructor () {
        this.__token = null;
        this.__loaded = new Deferred();
        this.__readOnly = true;
        this.__doneBefore = false;
        this.__noPermissions = false;
        this.userInfo = null;
        const token = sessionStorage.getItem('Token');
        if (token) {
            this.validateToken(token);
        } else {
            this.__loaded.resolve();
        }
    }

    static get instance () {
        return __instance ?? (__instance = new AuthManager());
    }

    get loaded () {
        return this.__loaded.promise;
    }

    get noPermissions () {
        return this.__noPermissions;
    }

    get token () {
        return this.__token;
    }

    get isReadOnly () {
        return this.__readOnly;
    }

    get isAuthorized () {
        return !!this.token;
    }

    get doneBefore () {
        return this.__doneBefore;
    }

    validateToken (token) {
        return ws.callRestApiPost('generic', 'logon2', 'user',
            {
                // Subject: [{Token: {token}}],
                RequiredUserInfo: {
                    IncludeUserData: ['AdminSettings', 'ContactInfo']
                }
            },
            {
                putAccessToken: null,
                tokenInHeaders: false,
                headers: {AccessToken: token}
            }).then(userInfos => this.validToken(token, userInfos.Users[0], null), () => {
            this.__loaded.resolve();
        });
    }

    validToken (token, userInfo) {
        if (_.some(userInfo.Permissions, p => p.substring(0, 'Admin'.length) === 'Admin')) {
            this.userInfo = new UserInfo2(userInfo);
            sessionStorage.setItem('Token', this.__token = token);
            const lang = this.userInfo.AdminSettings.language;
            localStorage.setItem('theme', document.body.dataset.theme = this.userInfo.AdminSettings.theme);
            if (lang && lang !== app().options.locale) {
                document.cookie = 'LanguageOverride=' + lang + ';max-age=31536000';
                window.location.reload();
            }
            this.__doneBefore = true;
            this.__noPermissions = false;
            this.__loaded.resolve();
            return true;
        } else {
            this.__noPermissions = true;
            this.__loaded.resolve();
            return false;
        }
    }

    clear () {
        sessionStorage.removeItem('Token');
        this.__token = null;
        this.userInfo = null;
    }
}
