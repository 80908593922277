import sd from 'fb-utils/serviceDiscovery';
export default async function () {
    const newQuery = [];
    let ticket;
    (window.location.search || '?').slice(1).split('&')
        .forEach(item => {
            if (item === '') { return; }
            const [key, value] = item.split('=', 2);
            switch (key.toLowerCase()) {
            case 'token':
                sessionStorage.setItem('Token', value);
                break;
            case 'jwt':
                sessionStorage.setItem('JWT', value);
                break;
            case 'ticket':
                ticket = value;
                break;
            default:
                newQuery.push((key) + '=' + (value));
                break;
            }
        });
    if (ticket) {
        const url = await sd.instance().GetApi('generic', 'logon2');
        const response = await fetch(url + '/tokens/' + encodeURIComponent(ticket), {method: 'GET'});
        const tokens = await response.json();
        if (tokens.JWT?.Value?.length) { sessionStorage.setItem('JWT', tokens.JWT.Value); }
        if (tokens.Token?.Value?.length) { sessionStorage.setItem('Token', tokens.Token.Value); }
    }
    window.history.pushState({}, '', window.location.pathname + (newQuery.length ? '?' + newQuery.join('&') : ''));
}
