import fa from '../../css-mixins/fontawesome.css';

export function load (callback) {
    require.ensure(['./module'], function (require) {
        callback(require('./module'));
    });
}

const menu = [
    {
        'inMainMenu': false,
        'path': '',
        'handler': 'Frontpage'
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'MailGroups',
        'icon': fa.envelope,
        'path': 'MailGroups',
        'handler': 'MailGroups',
        'index': 41,
        'requirePermissions': ['Admin.Mailer']
    },
    {
        'inMainMenu': false,
        'path': 'Mails/:id',
        'handler': 'mail',
        props: {
            module: load,
            viewLoader: 'mailBackbone'
        }
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'RedisCacheManagement',
        'icon': fa['dropbox'],
        'path': 'Redis',
        'handler': 'Redis',
        'index': 70,
        'requirePermissions': ['Admin.Redis']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'TaskManagement',
        'icon': fa.cog,
        'path': 'TaskManager',
        'handler': 'AsyncTasks',
        'index': 50,
        'requirePermissions': ['Admin.AsyncTasks']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'Logs',
        'icon': fa['file-text-o'],
        'path': 'Logs',
        'handler': 'Logs',
        'index': 60,
        'requirePermissions': ['Admin.Logs']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'Ec2Stats',
        'icon': fa['hdd-o'],
        'path': 'ec2-usage',
        'handler': 'EC2Usage',
        'index': 120,
        'requirePermissions': ['Admin']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'Ec2Security',
        'icon': fa.lock,
        'path': 'ec2-security',
        'handler': 'EC2SecurityAudit',
        'index': 121,
        'requirePermissions': ['Admin.Security']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'Ec2SecConfigEditor',
        'icon': fa.lock,
        'path': 'ec2-security/config',
        'handler': 'ConfigEditor',
        'index': 122,
        'requirePermissions': ['Admin.Security']
    },
    {
        'inMainMenu': true,
        'category': 'tools',
        'name': 'Ec2TempAccess',
        'icon': fa.lock,
        'path': 'ec2-security/TempAccess',
        'handler': 'EC2TempAccess',
        'index': 121,
        'requirePermissions': ['Admin.Security']
    }
];
export {menu};
